import React from "react"
import MailChimpForm from "../components/mailchimpform"
import { Link, graphql, useStaticQuery } from "gatsby"

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`

const Newsletter = () => {
  const data = useStaticQuery(pageQuery)
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const header = (
    <Link className="header-link-home" to="/">
      {siteTitle}
    </Link>
  )
  return (
    <div className="global-wrapper">
      <header style={{ marginBottom: "2em" }} className="global-header">
        {header}
      </header>
      <MailChimpForm text="Subscribe now and you will get notified every time I publish a new article." />
    </div>
  )
}

export default Newsletter
